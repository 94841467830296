import React from "react";
import { graphql } from "gatsby";
// import Content, { HTMLContent } from "../components/Content";
// import { Link } from "gatsby";
import Helmet from "react-helmet";
import Page from "../components/page";
import Section from "../components/section";
import FullWidthImage from "../components/FullWidthImage";
import { Title } from "../components/text";
// import CareerSubmit from "../components/career_submit";
import CareerItem from "../components/career_item";

export const CareerPageTemplate = ({
  title,
  postings_title,
  image,
  posts,
  content,
  contentComponent,
}) => {
  // const PageContent = contentComponent || Content;
  // console.log(posts);
  // console.log("postings_title", postings_title);
  return (
    <Page noBottomPadding>
      <Helmet title="Wesmans | Career" />
      <FullWidthImage image={image} height="50vh" parallax={50} dim={0.4}>
        <Title bright>{title}</Title>
      </FullWidthImage>
      <Section title={postings_title} diffBg>
        {posts.map((post) => (
          <CareerItem
            title={post.frontmatter.title}
            date={post.frontmatter.date}
            excerpt={post.frontmatter.description}
            buttonText="Read more"
            id={post.fields.slug}
            key={post.fields.slug}
          />
        ))}
      </Section>
    </Page>
  );
};
// <Section>
//   <CareerSubmit />
// </Section>

export default ({ data }) => {
  // console.log(data);
  const { markdownRemark: post } = data;
  let { edges: posts } = data.allMarkdownRemark;
  posts = posts.filter(
    (post) => post.node.frontmatter.templateKey === "career-posting"
  );
  posts = posts.map(({ node: post }) => post);

  return (
    <CareerPageTemplate
      title={post.frontmatter.title}
      postings_title={post.frontmatter.postings_title}
      image={post.frontmatter.image}
      posts={posts}
    />
  );
};

export const careerPageQuery = graphql`
  query CareerPage($id: String!) {
    allMarkdownRemark(sort: { order: ASC, fields: [frontmatter___order] }) {
      edges {
        node {
          excerpt(pruneLength: 400)
          id
          fields {
            slug
          }
          frontmatter {
            title
            description
            order
            templateKey
            date(formatString: "MMMM DD, YYYY")
          }
        }
      }
    }
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        title
        description
        order
        postings_title
        image {
          childImageSharp {
            fluid(maxWidth: 2000, quality: 64) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`;
